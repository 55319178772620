.maintenance-container {
  color: #0d60ae;
  h1 {
    font-size: 45px;
    margin-bottom: 10px;
  }
}

.logo {
  width: 200px;
  margin: 40px 0 20px 0;
}
