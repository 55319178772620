#invite-participants-submission {
  .elmo-nativetable-tr {
    border-top: unset;
  }

  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(0, 0, 0, 0.38);
    fill: currentcolor;
    text {
      fill: rgb(255, 255, 255);
      font-size: 0.75rem;
      font-family: Roboto, Helvetica, Arial, sans-serif;
    }
  }

  .MuiStepConnector-root {
    padding: 8px 0 8px;
    margin-left: 14px;
  }

  .completed {
    color: #006631;
    z-index: 1;
  }

  .error {
    color: #c21f39;
  }
}
