#invite-schedules {
  .elmo-formitem--on-error {
    .MuiInputBase-input {
      border-color: #ff9fa7 !important;
    }
  }
  .MuiTextField-root {
    width: 100%;
    .Mui-disabled {
      input {
        background-color: #f6f6f6;
      }
    }
    .MuiInput-underline {
      &:before {
        border-bottom: unset;
      }
      &:after {
        border-bottom: unset;
      }
    }
  }
}
