#select-all {
  label {
    font-weight: 650;
  }
}

#filters-user-list-search {
  .elmo-search__wrapper {
    padding: unset;
  }
  .elmo-search__btn-clear {
    right: 1px;
  }
  .elmo-search__btn-submit {
    display: none;
  }
}
