#invite-participants-step-1 {
  .elmo-modal__content {
    overflow: visible;
  }
  #input-recipient-count {
    font-size: 16px;
    color: #545454;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 8px;
    float: right;
  }
}
