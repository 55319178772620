.card-with-outer-heading {
  .heading-row {
    margin-bottom: 14px;
  }
  .heading-label-wrapper {
    display: inline-flex;
    margin-top: 10px;
    .heading {
      display: inline-block;
      margin-left: 16px;
      flex-shrink: 0;
    }
  }
}
