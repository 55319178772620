#submission-summary {
  #manage-invites-submission {
    float: right;
  }
  #manage-invites-sendSchedule {
    float: right;
  }
  #manage-invites-reminder {
    float: left;
  }
  .submission-divider {
    text-align: center;
  }
}
