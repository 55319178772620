@import './param';

/**
 * Screen Size Media Queries
*/
@mixin small-mobile-only {
  @media (max-width: #{$small-mobile-max}) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-min}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) {
    @content;
  }
}

@mixin tablet-max {
  @media (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-min}) {
    @content;
  }
}

@mixin desktop-max {
  @media (max-width: #{$desktop-max}) {
    @content;
  }
}

@mixin desktop-lg {
  @media (min-width: #{$desktop-lg-min}) {
    @content;
  }
}

@mixin desktop-lg-max {
  @media (max-width: #{$desktop-lg-max}) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: #{$desktop-xl-min}) {
    @content;
  }
}

@mixin desktop-xl-max {
  @media (max-width: #{$desktop-xl-max}) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (min-width: #{$desktop-xxl-min}) {
    @content;
  }
}
@mixin scroll-smooth {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
