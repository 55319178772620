#survey-layout-wrapper {
  background-color: #ffffff;

  #survey-layout {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    @media (min-width: 1200px) {
      max-width: 1248px;
    }
  }
}
