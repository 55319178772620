@import '/src/style/main';

.import-csv-template {
  .elmo-col:first-child {
    @include tablet-max {
      margin-bottom: 26px;
    }
  }

  .import-csv-template-card {
    height: 100%;
    background-color: #f6f6f6;
    .elmo-card__header {
      margin-bottom: 24px;
    }
  }
}
