#email-invites-messages-body-wrapper {
  @media screen and (min-width: 992px) {
    max-width: 960px;
    margin: auto;
  }

  @media screen and (min-width: 1200px) {
    max-width: 60%;
    min-width: 960px;
    margin: auto;
  }
}
