.popup-modal {
  .elmo-modal__footer-close {
    display: unset;
  }

  &.together {
    .secondary-button {
      float: left;
      margin-right: 8px;
    }

    .primary-button {
      float: right;
    }
  }
  &.separate {
    .elmo-modal__footer-primary-btn {
      width: 100%;

      .secondary-button {
        float: left;
        .elmo-btn--default {
          color: #19579f;
        }
      }

      .primary-button {
        float: right;
      }
    }
  }
}
