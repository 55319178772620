@import '/src/style/main.scss';

.elmo-header__title-addon {
  padding-left: 16px;
  padding-top: 4px;
}

@media (min-width: 600px) {
  .elmo-header__title-block-wrapper .elmo-header__sub-heading {
    font-size: 16px;
  }
}

@include desktop-max {
  .elmo-header-wrapper.elmo-header-wrapper--has-tabs
    + .elmo-header-wrapper__spacing {
    height: 95px;
  }

  .elmo-layout__main-header
    .elmo-header-wrapper.elmo-header-wrapper--search
    + .elmo-header-wrapper__spacing {
    height: 64px;
  }

  .elmo-header-wrapper + .elmo-header-wrapper__spacing {
    height: 48px;
  }
}
