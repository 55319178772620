#stepper {
  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.07px;
    color: rgba(0, 0, 0, 0.87);
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #808080;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 25px;
  }

  .MuiStepIcon-root {
    &.Mui-active {
      color: #3f51b5;
    }
    &.Mui-completed {
      color: #006631;
    }
  }
}
