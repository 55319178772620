.react-tagsinput {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  overflow: hidden;
  min-height: 200px;

  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  .react-tagsinput-input {
    input {
      outline: none;
    }
  }

  .react-tagsinput-tag {
    box-sizing: content-box;
    display: inline-flex;
    margin: 8px 1px 1px 8px;
    word-break: break-word;
    align-content: center;
    border-radius: 3px;
    background-color: #e6f1ff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
    padding: 5px;
    .tag-label {
      padding: 0 0 0 8px;
      transition: padding-right 0.25s;
      font-size: 16px;
      line-height: 36px;
      color: #19579f;
    }
    .tag-remove-btn {
      cursor: pointer;
      padding: 4px 9px;
      svg {
        background-color: #19579f;
        color: #e6f1ff;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        padding: 1px;
        transition: opacity 0.25s;
      }
    }
  }
}

.react-tagsinput--focused {
  border: 1px solid #129fea;
}

#emailInputValidationError {
  white-space: pre-line;
}

.elmo-formitem--on-error {
  .elmo-formitem__control__item {
    .react-tagsinput {
      border-color: #ff9fa7;
    }
  }
}
