#stepper-with-card {
  @media (min-width: 1024px) {
    &.content-fixed {
      position: fixed;
      top: 180px;
      width: 370px;
    }
  }

  @media (min-width: 1280px) {
    &.content-fixed {
      top: 166px;
    }
  }
}
