.invite-message-summary {
  .elmo-card {
    margin-bottom: 24px;
    .heading-row {
      padding: 12px 12px 0 12px;

      .message-actions {
        float: right;
        margin-left: auto;

        .actions-left-spacing {
          margin-left: 8px;
        }
      }
    }

    tbody {
      .elmo-nativetable-tr {
        border-top: none;

        .elmo-nativetable-th {
          color: #545454;
        }
      }
    }
  }
}
