#root {
  height: 100%;
  overflow-x: hidden;
}

.List {
  //border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.filters-users-list {
  .list-item {
    &.even {
      background-color: #ededed;
    }
    padding: 12px;
    line-height: 24px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
  }
}
