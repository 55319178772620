#invite-recipients-type-selector {
  h5 {
    margin-bottom: 26px;
  }
  .elmo-card__header-image {
    margin-right: auto;
    margin-left: auto;
    min-width: unset;
    svg {
      width: 40%;
      height: 138px;
    }
  }
  .elmo-formitem {
    width: 100%;
  }
}
