.elmo-header-wrapper {
  top: unset;
}

.elmo-header-wrapper + .elmo-header-wrapper__spacing {
  height: 80px;
}

#root > .elmo-layout__main-content-wrapper {
  overflow: unset;
}
