.datetime-field {
  .MuiTextField-root {
    width: 100%;
    height: 45px;
    border-color: rgba(0, 0, 0, 0.23);
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    .MuiInputBase-input {
      padding: 12px 16px;
      height: 20px;
    }
    .Mui-disabled {
      input {
        background-color: #f6f6f6;
      }
    }

    .MuiInput-underline {
      &:before,
      &:hover:before {
        border-bottom: unset;
      }
      &:after {
        border-bottom: unset;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }
}
