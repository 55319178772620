.loader-overlay {
  position: fixed;
  visibility: visible;
  transition: all 0.3s;
  outline: 0;
  opacity: 1;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .elmo-loader-overlay__bg {
    position: absolute;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .elmo-loader-overlay__content {
    position: fixed;
    top: 45%;
    left: 50%;
    font-size: 16px;
    text-align: center;
    .elmo-spinner {
      svg {
        color: #ffffff;
        width: 100px;
        height: 100px;
      }
    }
  }
}
