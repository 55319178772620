.no-padding-top {
  padding-top: unset !important;
}

.no-padding-left {
  padding-left: unset !important;
}

.empty-state-no-content {
  margin-top: 50px;
  .elmo-row {
    margin-bottom: 20px;
    white-space: pre-line;
    .elmo-icon {
      svg {
        font-size: 150px;
      }
    }
  }
  .elmo-icon__svg {
    font-size: 150px;
  }
}

button.text-button {
  color: #19579f;
}

svg.header-icon {
  margin-right: 10px;
  margin-bottom: 4px;
}

.elmo-formitem--on-default {
  .elmo-formitem__control__help {
    font-style: italic;
    padding-left: 2px;
  }
}

div.elmo-divider {
  margin-top: 18px;
  margin-bottom: 18px;
}

.elmo-select {
  margin-bottom: unset;
}

.elmo-formitem--on-error {
  .fr-box.fr-basic {
    .fr-toolbar {
      border-color: #ff9fa7;
    }

    .fr-wrapper {
      border-left-color: #ff9fa7;
      border-right-color: #ff9fa7;
    }

    .second-toolbar {
      border-color: #ff9fa7;
    }
  }
  .elmo-select {
    border-color: #ff9fa7;
  }
}

#PopoverContainer,
.elmo-modal__body-inner {
  white-space: pre-line;
}

.row-padding {
  padding-top: 10px;
}

.align-right {
  float: right;
}

.clickable {
  cursor: pointer;
}
