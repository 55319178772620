.card-active-icon {
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 100;
  color: #2972c4;
  background: white;
}

.card-button {
  &.card-active:before {
    opacity: 1;
  }

  .card-button-icon {
    width: 100px;
    margin: 0 auto;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .card-button-heading {
    h4 {
      margin: 0 auto;
    }
  }

  .card-button-description {
    p {
      margin: 0 auto;
      white-space: initial;
    }
  }
}
