#login-container {
  margin-left: auto;
  margin-right: auto;
  width: 440px;
  > div {
    padding: 40px;
    margin-top: 20px;
  }
  .well {
    min-height: 20px;
    padding: 40px;

    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;

    #login-box {
      margin-left: 26px;
      margin-right: 26px;
      padding: 20px 0;
      p {
        text-align: center;
      }
      .logo {
        display: block;
        margin: auto;
        max-width: 368px;
        max-height: 140px;
        padding-bottom: 20px;
      }
      .elmo-alert {
        width: 100%;
      }
      .bottom-spacing {
        margin-bottom: 30px;
      }
    }
  }
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
