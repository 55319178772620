.summary-card {
  .card-title {
    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.07px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .card-footer {
    margin-bottom: -1rem;
    .elmo-divider {
      margin: 0 -20px;
    }
    .elmo-btn__label {
      white-space: nowrap;
    }
  }
  .secondary-action {
    margin-right: 10px;
  }
  .elmo-nativetable {
    tbody {
      .elmo-nativetable-tr {
        border-top: unset;
      }
      .elmo-nativetable-th {
        padding-left: unset;
      }
    }
  }
}
